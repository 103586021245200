import { InvoiceMethod } from "@simplicate/api-client";
import {
  Big,
  type HourTypeInServiceBody,
  type HourTypeInService,
  transformMoneyToMoneyFromAPI,
  HourType,
} from "../../data";
import { HourTypeInForm, ValidHourTypeInForm } from "./types";
import { type WithSortOrder } from "./utils";

export const transformNewHourTypeToHourTypeInForm = ({ id, name, hourlyRate }: HourType): HourTypeInForm => ({
  id: id,
  name: name,
  amount: 1,
  isInvoiceable: true,
  hourlyRate: hourlyRate,
  total: hourlyRate,
  hasRegistrations: false,
});

export const transformHourTypeInFormToHourType = (
  { id, hourlyRate, amount, isInvoiceable, sortOrder }: WithSortOrder<ValidHourTypeInForm>,
  invoiceMethod: InvoiceMethod,
): HourTypeInServiceBody => ({
  defaultHourTypeId: id,
  hourlyRate: transformMoneyToMoneyFromAPI(hourlyRate),
  amountOfHours: amount,
  isInvoiceable: invoiceMethod === InvoiceMethod.time_and_expenses ? isInvoiceable ?? null : null,
  sortOrder,
});

export const transformHourTypeInServiceToHourTypeInForm = ({
  defaultHourType,
  amountOfHours,
  isInvoiceable,
  hourlyRate,
  hasRegistrations,
}: HourTypeInService): HourTypeInForm => ({
  id: defaultHourType.id,
  name: defaultHourType.name,
  amount: amountOfHours,
  isInvoiceable: isInvoiceable ?? true,
  hourlyRate: {
    amount: Big(hourlyRate.amount),
    currency: hourlyRate.currency,
  },
  total: {
    amount: Big(hourlyRate.amount).mul(amountOfHours),
    currency: hourlyRate.currency,
  },
  hasRegistrations,
});
