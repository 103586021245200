import { InvoiceMethod } from "@simplicate/api-client";
import {
  BuiltProjectService,
  FixedPriceService,
  ProjectService,
  SubscriptionService,
  transformMoneyFromAPIToMoney,
} from "../../data";
import { transformCostTypeInServiceToCostTypeInForm } from "./transformCostTypes";
import { transformEmployeeHourlyRatesInForm } from "./transformEmployeeHourlyRates";
import { transformHourTypeInServiceToHourTypeInForm } from "./transformHourTypes";
import { ProjectServiceForm } from "./types";
import { sortBySortOrder } from "./utils";

const transformFixedPriceInvoice = (
  base: ProjectServiceForm,
  { invoiceableFrom, invoiceInInstallments, invoicePrice, hasInstallmentPlan }: FixedPriceService,
): ProjectServiceForm => ({
  ...base,
  invoiceMethod: InvoiceMethod.fixed_price,
  invoiceableFrom: invoiceableFrom ? new Date(invoiceableFrom) : undefined,
  invoiceInInstallments: invoiceInInstallments,
  invoicePrice: transformMoneyFromAPIToMoney(invoicePrice.price),
  hasInstallmentPlan: hasInstallmentPlan,
});

const transformSubscriptionInvoice = (
  base: ProjectServiceForm,
  { registrationTimeFrame, subscriptionCycle, invoicePrice, invoiceTogetherWith }: SubscriptionService,
): ProjectServiceForm => ({
  ...base,
  invoiceMethod: InvoiceMethod.subscription,
  subscriptionCycle,
  invoicePrice: transformMoneyFromAPIToMoney(invoicePrice.price),
  registrationTimeFrame: {
    startDate: registrationTimeFrame?.startDate ? new Date(registrationTimeFrame.startDate) : undefined,
    endDate: registrationTimeFrame?.endDate ? new Date(registrationTimeFrame.endDate) : undefined,
  },
  hasRegistrationTimeFrame: !!registrationTimeFrame?.startDate || !!registrationTimeFrame?.endDate,
  invoiceTogetherWith,
});

const transformTimeAndExpensesInvoice = (base: ProjectServiceForm): ProjectServiceForm => ({
  ...base,
  invoiceMethod: InvoiceMethod.time_and_expenses,
});

export const transformHoursRegistrationConfiguration = (
  hoursRegistrationConfiguration: BuiltProjectService["hoursRegistrationConfiguration"],
): ProjectServiceForm["hoursRegistrationConfiguration"] => ({
  employeeRateAgreements: hoursRegistrationConfiguration
    ? transformEmployeeHourlyRatesInForm(hoursRegistrationConfiguration.employeeRateAgreements)
    : undefined,
  hourTypeRateAgreements: hoursRegistrationConfiguration.hourTypeRateAgreements?.map((agreement) => ({
    id: agreement.hourTypeId,
    hourlyRate: transformMoneyFromAPIToMoney(agreement.hourlyRate),
  })),
});

export const transformProjectServiceToForm = (projectService: ProjectService): ProjectServiceForm => {
  const {
    defaultServiceId,
    invoiceMethod,
    description,
    explanation,
    revenueGroupId,
    vatCodeId,
    isPlannable,
    existsOnInvoice,
    hasAssignments,
    timeFrame: timeframe,
    hoursRegistrationConfiguration: { hourTypeTotals, canRegisterHours, hourTypes, employeeHourlyRates },
    costsRegistrationConfiguration: { canRegisterCosts, costTypes, registrationEndDate },
  } = projectService;

  const base: ProjectServiceForm = {
    defaultService: defaultServiceId,
    description,
    explanation,
    timeframe: {
      startDate: timeframe?.startDate ? new Date(timeframe.startDate) : undefined,
      endDate: timeframe?.endDate ? new Date(timeframe.endDate) : undefined,
    },
    revenueGroup: revenueGroupId,
    vatCode: vatCodeId,
    canRegisterHours,
    canRegisterCosts,
    isPlannable,
    hourTypes: sortBySortOrder(hourTypes).map((hourType) => transformHourTypeInServiceToHourTypeInForm(hourType)),
    hourTypesSpecifiedTotal: hourTypeTotals ? transformMoneyFromAPIToMoney(hourTypeTotals.specifiedTotal) : undefined,
    costTypes: sortBySortOrder(costTypes).map((costType) => transformCostTypeInServiceToCostTypeInForm(costType)),
    hasRegistrationEndDate: Boolean(registrationEndDate),
    registrationEndDate: registrationEndDate ? new Date(registrationEndDate) : undefined,
    existsOnInvoice,
    hasAssignments,
    employeeHourlyRates: transformEmployeeHourlyRatesInForm(employeeHourlyRates),
    hoursRegistrationConfiguration: transformHoursRegistrationConfiguration(
      projectService.hoursRegistrationConfiguration,
    ),
  };

  if (invoiceMethod === InvoiceMethod.fixed_price) {
    return transformFixedPriceInvoice(base, projectService);
  }

  if (invoiceMethod === InvoiceMethod.subscription) {
    return transformSubscriptionInvoice(base, projectService);
  }

  return transformTimeAndExpensesInvoice(base);
};
